<ion-header *ngIf="!authPage">
  <ion-toolbar>
    <ion-title>{{ 'PAYMENT.TITLE_ADD' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="toolbar-button" (click)="close()">
        {{ 'APP.CLOSE' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <iframe
    *ngIf="url"
    class="payment-iframe"
    [ngClass]="{ 'frame-loaded': frameLoaded }"
    [src]="url"
    [id]="appVersion"
    [name]="appVersion"></iframe>

  <div class="ion-padding" *ngIf="!frameLoaded">
    <div class="ion-text-center">
      <ion-spinner name="dots"></ion-spinner>
    </div>
    <div class="ion-text-center ion-padding-top">
      <a
        href="https://www.braintreegateway.com/merchants/2b8tv22q2hkjsrtd/verified"
        target="_blank">
        <img
          src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png"
          width="280px"
          height="44px"
          border="0" />
      </a>
    </div>
  </div>
  <ion-button
    expand="block"
    fill="clear"
    class="ion-margin-top"
    *ngIf="authPage"
    (click)="skip()">
    {{ 'AUTH.SKIP' | translate }}
  </ion-button>
</ion-content>
