import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  NavController,
  NavParams,
  AlertController,
  ModalController,
  ActionSheetController
} from '@ionic/angular';
import { GlobalsService } from 'src/app/services/globals/globals.service';
import { Storage } from '@ionic/storage-angular';
import { HttpInterceptorService } from 'src/app/services/http-interceptor/http-interceptor.service';
import { UserService } from 'src/app/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { BackgroundColor, InAppBrowser } from '@capgo/inappbrowser';
import moment from 'moment-timezone';
import { NewCardComponent } from '../new-card/new-card.component';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfig } from 'src/app/variables';
import { Location } from '@angular/common';
import { AbraAuthService } from 'src/app/services/abraAuth/abra-auth.service';
import { signOut } from 'aws-amplify/auth';
import { Capacitor } from '@capacitor/core';
import { AppLauncher } from '@capacitor/app-launcher';

const helper = new JwtHelperService();

@Component({
  selector: 'sc-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
  component: any;
  //userData: any = {};//{login:{}, register:{}, user:{}, customer:{}, reset:{}};
  resetSuccess: boolean = false;
  title: string;
  IDs: any = {};
  accessCardType: string;
  customer: any;
  inFocus: string;
  btLoading: boolean = false;
  btCanSubmit: boolean = false;
  btCanSeeButton: boolean = false;
  btInstance: any;

  language: any = { auth: {}, app: {} };

  showPass: boolean = false;
  appConfig: AppConfig = new AppConfig();
  // Fix later when we use common whitelabel service for both App and the Portal
  isFellTech =
    this.appConfig.appToken == '62d8aee3-7e03-472c-8067-6e78a4ed1497';
  loginForm: FormGroup;
  registerForm: FormGroup;
  forgotPasswordForm: FormGroup;

  constructor(
    public nav: NavController,
    private params: NavParams,
    private alertCtrl: AlertController,
    private gf: GlobalsService,
    public storage: Storage,
    private _http: HttpInterceptorService,
    private userService: UserService,
    private translate: TranslateService,
    private loading: LoadingService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private route: ActivatedRoute,
    private location: Location,
    private changeRef: ChangeDetectorRef,
    private abraAuth: AbraAuthService
  ) {
    this.setupForms();
    if (this.params.get('showLogin')) {
      this.title = 'LOGIN';
    } else if (this.params.get('validationKey')) {
      try {
        let token = helper.decodeToken(this.params.get('validationKey'));

        if (token) {
          if (token.Email)
            this.registerForm.controls.email.patchValue(token.Email);

          if (token.Language) {
            this.registerForm.controls.language.patchValue(token.Language);
            this.translate.use(token.Language); //Finds the correct language
            this.storage.set('lang', token.Language); //Stores the language
          }
        }
      } catch (err) {
        //Could not decode token
      }
      this.title = 'COMPLETE_REGISTRATION';
    } else {
      this.title = 'REGISTER';
    }
  }

  ngOnInit() {}

  loginWithAbra() {
    this.abraAuth.loginWithAbra().then((user) => {
      console.log(user);
    });
  }

  showComponent(component) {
    this.component = component;
  }

  openAgreements() {
    const lang = this.translate.currentLang || this.appConfig.defaultLang; //Fallback to default language
    const termsURL =
      this.appConfig.termsAndConditions[lang] ||
      Object.values(this.appConfig.termsAndConditions)[0]; //Fallback to first available language
    const privacyURL =
      this.appConfig.privacyPolicy[lang] ||
      Object.values(this.appConfig.privacyPolicy)[0]; //Fallback to first available language

    this.actionSheetCtrl
      .create({
        buttons: [
          {
            text: this.translate.instant('APP.TERMS'),
            handler: () => {
              if (Capacitor.isNativePlatform()) {
                InAppBrowser.openWebView({
                  url: termsURL,
                  title: this.translate.instant('APP.TERMS'),
                  backgroundColor: BackgroundColor.WHITE
                });
              } else {
                AppLauncher.openUrl({ url: termsURL });
              }
            }
          },
          {
            text: this.translate.instant('APP.PRIVACY'),
            handler: () => {
              if (Capacitor.isNativePlatform()) {
                InAppBrowser.openWebView({
                  url: privacyURL,
                  title: this.translate.instant('APP.PRIVACY'),
                  backgroundColor: BackgroundColor.WHITE
                });
              } else {
                AppLauncher.openUrl({ url: privacyURL });
              }
            }
          },
          {
            text: this.translate.instant('APP.CANCEL'),
            role: 'cancel'
          }
        ]
      })
      .then((actionSheet) => {
        actionSheet.present();
      });
  }

  login(registering?, event?) {
    if (event) event.preventDefault();

    if (this.loginForm.invalid) {
      this.showError(this.translate.instant('AUTH.NO_CREDENTIALS'));
    } else {
      if (!registering) {
        this.loading.show();
      }

      this.userService
        .login(
          this.loginForm.value.email.toLowerCase(),
          this.loginForm.value.password,
          registering
        )
        .then(
          (customer) => {
            this.userService.setIsAdhocUser(false);
            this.customer = customer;
            if (registering) {
              this.setupBraintree();
            } else {
              this.loading.dismiss();
              this.modalCtrl.dismiss(customer);
            }
          },
          (err) => {
            this.showError(err);
          }
        );
    }
  }

  fixName(nameToFix: string): string {
    if (!nameToFix) return nameToFix;

    return nameToFix
      .toLowerCase()
      .trim()
      .split(' ')
      .filter((s) => s !== '')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
      .replace(/\r?\n|\r/gi, '');
  }

  register(title) {
    if (this.registerForm.invalid) {
      this.showError(this.translate.instant('AUTH.NO_CREDENTIALS'));
    } else {
      this.loading.show();
      //Create user

      if (!this.registerForm.value.language) {
        //If no language we get the current lang. //FIX: Move this to init when we add language selector
        //Current lang is set when app opens first time or in settings

        let lang = this.translate.currentLang;
        this.registerForm.controls.language.patchValue(lang);
      }

      let postRegister = {
        FirstName: this.fixName(this.registerForm.value.firstName),
        LastName: this.fixName(this.registerForm.value.lastName),
        Email: this.registerForm.value.email.toLowerCase(),
        Language: this.registerForm.value.language,
        Password: this.registerForm.value.password,
        TimezoneID: moment.tz.guess(),
        appToken: this.appConfig.appToken
      };

      if (this.params.get('validationKey')) {
        this.userService.setIsAdhocUser(false);
        this.doCompleteRegistration(postRegister);
      } else if (this.params.get('adhoc')) {
        this.adhocRegistration(postRegister);
      } else {
        this.userService.setIsAdhocUser(false);
        this.doRegister(postRegister);
      }
    }
  }

  doRegister(postRegister) {
    this._http.postUnsecure('Users/Register', postRegister).subscribe(
      (res) => {
        if (res && res.errorName) {
          this.showError(res.errorName);
        } else if (res && res.datas && res.datas.customer) {
          this.customer = res.datas.customer;

          //this.userData.customer = res.datas.customer;
          this.loginForm.patchValue(this.registerForm.value);
          this.login(true);
        } else {
          this.showError(res.errorName);
        }
      },
      (err) => {
        console.log(err);
        this.showError(err.errorName);
      }
    );
  }
  adhocRegistration(postRegister) {
    this.userService.getUser().then((user) => {
      let updatedUser = Object.assign({}, user, postRegister);
      this.userService.updateUser(updatedUser).then((res) => {
        this.storage.remove('adhoc').then((res) => {
          this.returnUser(updatedUser);
        });
      });
    });
  }
  doCompleteRegistration(postRegister) {
    postRegister.ValidationToken = this.params.get('validationKey');
    this._http
      .postUnsecure('Users/ConfirmUserRegistration', postRegister)
      .subscribe(
        (res) => {
          this.loginForm.patchValue(this.registerForm.value);
          this.login(true);
          this.location.replaceState('/stations');
        },
        (err) => {
          console.log(err);
        }
      );
  }

  registerRfid() {
    this.modalCtrl
      .create({
        component: NewCardComponent,
        componentProps: {
          page: 'auth'
        }
      })
      .then((modal) => {
        modal.onDidDismiss().then((newCard) => {
          this.skipRfid();
        });
        modal.present();
      });
  }

  returnUser(user) {
    this.title = 'SUCCESS';
    this.changeRef.detectChanges();

    setTimeout(() => {
      this.modalCtrl.dismiss(user);
    }, 3000);
  }

  show(page) {
    switch (page) {
      case 'REGISTER':
        this.registerForm.patchValue(this.loginForm.value);
        break;
      case 'LOGIN':
        this.loginForm.patchValue(this.registerForm.value);
        break;
      case 'FORGOT_PASSWORD':
        this.forgotPasswordForm.patchValue(this.loginForm.value);
        break;
    }
    this.title = page;
    this.changeRef.detectChanges();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  showError(error) {
    this.loading.dismiss();

    let defaultError = this.translate.instant('AUTH.DEFAULT_ERROR');

    switch (error) {
      case 'default':
        error = defaultError;
        break;
      case 'userAlreadyExists':
        error = this.translate.instant('AUTH.USER_EXIST');
        break;
      case 'wrongUsernameOrPassword':
        error = this.translate.instant('AUTH.WRONG_EMAIL_PASSWORD');
        break;
    }

    let title = '';
    if (this.title === 'REGISTER' || this.title === 'REGISTER_RFID') {
      title = this.translate.instant('AUTH.REGISTRATION_FAILED');
    } else {
      title = this.translate.instant('AUTH.LOGIN_FAILED');
    }

    this.alertCtrl
      .create({
        header: title,
        subHeader: error,
        buttons: ['OK']
      })
      .then((alert) => {
        alert.present();
      });
  }

  resetPassword() {
    if (this.forgotPasswordForm.invalid) {
      this.showError(this.translate.instant('AUTH.NO_CREDENTIALS'));
    } else {
      this.loading.show();

      this._http
        .getUnsecure(
          'Users/ResetPass/' +
            this.forgotPasswordForm.value.email +
            '/' +
            this.appConfig.appToken
        )
        .subscribe(
          (res) => {
            this.loading.dismiss();
            if (res.user) {
              //Add email to the loginform and redirect user
              this.loginForm.patchValue(this.forgotPasswordForm.value);
              this.resetSuccess = true; //Will show message to check email
              this.title = 'LOGIN';
            }
          },
          (err) => {
            this.loading.dismiss();
          }
        );
    }
  }

  setupBraintree() {
    this.loading.dismiss();
    this.title = 'REGISTER_PAYMENT';
  }

  skipPayment() {
    // this.title = 'REGISTER_RFID';
    this.returnUser(this.userService.user);
    //Ionic didn't detect changes when using browser to add payment
    // this.changeRef.detectChanges();
  }

  skipRfid() {
    //Skipping RFID registration and shows registration success message
    this.returnUser(this.userService.user);
  }

  onBlur() {
    this.inFocus = '';
  }

  onFocus(input) {
    this.inFocus = input;
  }

  setupForms() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });

    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.email],
      password: ['', Validators.required],
      language: ['']
    });

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.email]
    });
  }
}
