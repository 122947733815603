import { Injectable } from '@angular/core';
import { GlobalsService } from '../globals/globals.service';
import { Storage } from '@ionic/storage-angular';
import { AuthService } from '../auth/auth.service';
import { AlertController, ModalController } from '@ionic/angular';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { UserService } from '../user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from '../payment/payment.service';
import { AuthComponent } from 'src/app/components/auth/auth.component';
import { OverlayEventDetail } from '@ionic/core';
import { BoxRequestService } from '../box-request/box-request.service';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/variables';
import { LoginAlertComponent } from 'src/app/components/login-alert/login-alert.component';

/*
    A service for handling the user charging.

    Contents:
        Variables
            - charing: boolean
            - session: any

        Functions:
            - startCharging(box, point): Promise
            - checkIfStarted(rfid): Promise
            - getSession(id): Promise [DEPRICATED]
            - getSessions(): Promise
            - isCharging(): Promise [NOT IN USE] -> clearCharging()
            - stopCharging(deviceID, sessionID)
            - getChargingData(sessionKey): Promise
            - clearCharging()
            appid
            e5bbc4b19a511c357fa5278ebe2065238c0136cc09b0ee99

            clientid
            mobile_sdk_client_ef9891c796399fe146bb
*/
var runs = 0;

@Injectable({
  providedIn: 'root'
})
export class ChargingService {
  runs: number = 0;
  charging: boolean;
  session: any = {};
  sessions: any = [];
  appConfig = new AppConfig();

  constructor(
    public gf: GlobalsService,
    public storage: Storage,
    public auth: AuthService,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public _http: HttpInterceptorService,
    private userService: UserService,
    private translate: TranslateService,
    private payment: PaymentService,
    private boxReq: BoxRequestService,
    private router: Router
  ) {}

  startCharging(point: any, skipPaymentCheck = false): Promise<any> {
    /*
            Starts charging if checks are success.
            Checks:
                - Authentication
                - Payment method registered

            Resolves a temporary charging response

            resolve(false) if cancelled by user

            Rejects:
             - remoteStartError
             - checkPaymentError
             - noUser (will never happen really, because user is prompted to log in before this)
             - {payment-rejects}
             - ++
        */

    return new Promise((resolve, reject) => {
      this.checkIfLoggedIn(point).then(
        (authenticated) => {
          if (authenticated === 'adhoc') {
            resolve('AdhocUser');
          } else {
            this.userService.getCustomerID().then(async (customerID) => {
              if (customerID) {
                if (await this.userService.isAdhocUser()) {
                  // checkReservation
                  this.payment
                    .getReservations(customerID)
                    .subscribe((reservations) => {
                      if (reservations.length) {
                        // start charging
                        this.remoteStart(point, customerID, resolve, reject);
                      } else {
                        resolve('NoReservationOpenPaymentSelector');
                      }
                    });
                } else {
                  this.payment
                    .checkPayment(customerID, point, skipPaymentCheck)
                    .then(
                      (success) => {
                        if (success) {
                          this.remoteStart(point, customerID, resolve, reject);
                        } else {
                          reject('checkPaymentError');
                        }
                      },
                      (err) => {
                        this.handleNoPayment(err, point).then((response) => {
                          reject(response);
                        });
                      }
                    );
                }
              } else {
                reject(`noUser`);
              }
            });
          }
        },
        (err) => {
          //Only happens when login modal is cancelled
          resolve(false);
        }
      );
    });
  }

  remoteStart(point, customerID, resolve, reject) {
    let startObject = {
      FK_ChargingPointID: point.PK_ChargePointID,
      FK_CustomerID: customerID,
      Origin: 'App'
    };
    this._http.post('Commands/RemoteStart', startObject).subscribe(
      (res) => {
        if (res && res.datas && res.datas.PK_CommandRequestID) {
          resolve(res.datas.PK_CommandRequestID);
        } else {
          reject('remoteStartError');
        }
      },
      (err) => {
        this.handleError(err.errorName);
        reject(err.errorName);
      }
    );
  }

  private handleNoPayment(err, point?): Promise<any> {
    return new Promise((resolve) => {
      //Point: If the point should be started after

      if (err === 'paypal_declined' || err === 'card_declined') {
        let title =
          err === 'paypal_declined'
            ? this.translate.instant('PAYMENT.PAYPAL_DECLINED_TITLE')
            : this.translate.instant('PAYMENT.CARD_DECLINED_TITLE');
        let message =
          err === 'paypal_declined'
            ? this.translate.instant('PAYMENT.PAYPAL_DECLINED_MESSAGE')
            : this.translate.instant('PAYMENT.CARD_DECLINED_MESSAGE');

        this.alertCtrl
          .create({
            header: title,
            message: message,
            buttons: [
              {
                text: this.translate.instant('APP_NAVIGATION.SETTINGS'),
                handler: () => {
                  this.navigateToAddPayment();
                  resolve('processor_declined');
                }
              },
              {
                text: this.translate.instant('APP.CANCEL'),
                role: 'cancel',
                handler: () => {
                  resolve(false);
                }
              }
            ]
          })
          .then((alert) => {
            alert.present();
          });
      } else if (err === 'no_payment') {
        this.alertCtrl
          .create({
            header: this.translate.instant('PAYMENT.REGISTER_PAYMENT'),
            message: this.translate.instant('PAYMENT.MUST_REGISTER'),
            buttons: [
              {
                text: this.translate.instant('ASK_FOR_CREDENTIALS.REGISTER'),
                handler: () => {
                  this.navigateToAddPayment();
                  resolve(err);
                }
              },
              {
                text: this.translate.instant('ASK_FOR_CREDENTIALS.LATER'),
                role: 'cancel',
                handler: () => {
                  resolve(false);
                }
              }
            ]
          })
          .then((alert) => {
            alert.present();
          });
      } else {
        this.handleError(null);
        resolve(false);
      }
    });
  }
  private navigateToAddPayment() {
    this.payment.setRedirectPath(this.router.url);
    this.router.navigate(['settings/payment']);
  }
  private handleError(errorMessage) {
    //Show some error
    //errorMessage can be serviceerror(404) and API-error(notFoundError)

    this.alertCtrl
      .create({
        header: this.translate.instant('ERROR.ERROR_CHARGING'),
        message: this.translate.instant('ERROR.ERROR_CHARGING_DETAILED'),
        buttons: [
          {
            text: this.translate.instant('APP.OK'),
            role: 'cancel'
          }
        ]
      })
      .then((alert) => {
        alert.present();
      });
  }

  private checkIfLoggedIn(point?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth.authenticate().subscribe((authenticated) => {
        if (!authenticated) {
          // check payment methods
          this.payment.getPossiblePaymentMethodsForPoint(point).subscribe({
            next: (res) => {
              this.modalCtrl
                .create({
                  component: LoginAlertComponent,
                  componentProps: { paymentMethods: res },
                  cssClass: 'auto-height padding-horizontal',
                  backdropDismiss: false
                })
                .then((modal) => {
                  modal.onDidDismiss().then((res) => {
                    switch (res?.data) {
                      case 'login':
                      case 'register':
                        this.modalCtrl
                          .create({
                            component: AuthComponent,
                            componentProps: { showLogin: res.data === 'login' }
                          })
                          .then((modal) => {
                            modal
                              .onDidDismiss()
                              .then((res: OverlayEventDetail) => {
                                if (res.data) resolve(true);
                                else resolve(false);
                              });
                            modal.present();
                          });
                        break;
                      case 'adhoc':
                        resolve('adhoc');
                        break;
                      case 'cancel':
                      default:
                        reject('modalCancel');
                        break;
                    }
                  });
                  modal.present();
                });
            },
            error: (err) => {}
          });
        } else {
          resolve(true);
        }
      });
    });
  }

  /*
    storeSessions(session){
        this.storage.get('sessions').then(sessions=>{
            if(sessions.length){
                //Get all that don't have the same device and rfid
                sessions = sessions.filter(item=>item.RFID!==session.RFID&&item.DeviceID!==session.DeviceID)[0];
                //sameAsNew skal ikke lagres, men det skal session
                sessions.push(session);
            }else{
                sessions = [session];
            }
            this.storage.set('sessions', sessions);
        });
    }*/

  getSession(id): Promise<any> {
    return new Promise((resolve) => {
      /*this.getSessions().then(sessions=>{
                if(sessions && sessions.length){

                    let session = sessions.filter(item => item.PK_ServiceSessionID===parseInt(id))[0];
                    this.session = session;
                    if(session){

                        this.storage.set('session', session);
                    }

                    resolve(session);
                }else{
                    resolve(false)
                }

            })   */
      this._http.get('ServiceSessions/' + id).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }
  getSessions(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getUserID().then((userID) => {
        if (userID) {
          this._http.get('ServiceSessions/Active/' + userID).subscribe(
            (sessions) => {
              this.charging = true;
              this.sessions = sessions;
              resolve(sessions);

              if (!sessions.length) {
                this.clearCharging();
              }
            },
            (err) => {
              reject(err);
            }
          );
        } else {
          this.clearCharging();
          resolve([]);
        }
      });
    });
  }

  getSessionsActiveIDs(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getUserID().then((userID) => {
        if (userID) {
          this._http.get('ServiceSessions/active-ids/' + userID).subscribe(
            (sessions) => {
              this.charging = true;
              this.sessions = sessions.map((item) => ({
                ...item,
                ChargingPointID: item.PK_ChargingPointID
              }));
              resolve(sessions);

              if (!sessions.length) {
                this.clearCharging();
              }
            },
            (err) => {
              reject(err);
            }
          );
        } else {
          this.clearCharging();
          resolve([]);
        }
      });
    });
  }

  isCharging(): Promise<any> {
    return new Promise((resolve) => {
      if (!this.session.Keys) {
        this.storage.get('session').then((session) => {});
        resolve(false);
      } else {
        this._http
          .get('ServiceSessions/' + this.session.Keys)
          .subscribe((res) => {
            if (res.isFinished) {
              this.clearCharging();
              resolve(false);
            } else {
              this.charging = true;
              resolve(true);
            }
          });
      }
    });
  }

  stopCharging(boxID, sessionID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get('Commands/RemoteStop/' + boxID + '/' + sessionID)
        .subscribe(
          (res) => {
            if (!res || !res.datas || !res.datas.PK_CommandRequestID) {
              resolve(false);
            } else {
              this.startWait(res.datas.PK_CommandRequestID, 60, 5).then(
                (success) => {
                  if (success) this.clearCharging();
                  resolve(success);
                },
                (err) => {
                  reject(err);
                }
              );
            }
          },
          (err) => {
            reject(err.errorName);
          }
        );
    });
  }
  startWait(requestID, duration, intervalSeconds): Promise<any> {
    return new Promise((resolve, reject) => {
      this.boxReq.startWait(requestID, duration, intervalSeconds).then(
        (success) => {
          resolve(success);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  removeFromQ(sessionID): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .get('LoadBalancing/ForcePutBackInTheQueue/' + sessionID)
        .subscribe((res) => {
          if (res.success) {
            resolve(true);
            this.showConfirmationQ();
          } else {
            resolve(false);
          }
        });
    });
  }
  showConfirmationQ() {
    this.storage.get('noshowQ').then((noShow) => {
      if (!noShow) return;
      this.alertCtrl
        .create({
          header: this.translate.instant('CHARGING.REMOVED_FROM_Q'),
          message: this.translate.instant('CHARGING.REMOVED_FROM_Q_DESC'),
          buttons: [
            {
              text: this.translate.instant('APP.OK'),
              role: 'cancel',
              handler: (data) => {
                if (data.length) {
                  this.storage.set('noshowQ', true);
                }
              }
            }
          ],
          inputs: [
            {
              type: 'checkbox',
              label: this.translate.instant('APP.DO_NOT_SHOW_AGAIN'),
              value: 'noshow',
              checked: false
            }
          ]
        })
        .then((alert) => {
          alert.present();
        });
    });
  }
  getChargingData(sessionID): Promise<any> {
    return new Promise((resolve, reject) => {
      if (sessionID) {
        this._http.get('ServiceSessions/LiveData/' + sessionID).subscribe({
          next: (chargingData) => {
            this.session.chargingData = chargingData;
            resolve(chargingData);
          },
          error: (err) => {
            reject(err);
          }
        });
      } else {
        resolve(null);
      }
    });
  }
  clearCharging() {
    this.storage.remove('activeDevice');
    this.charging = false;
    this.session = {};
    this.sessions = [];
  }
}
