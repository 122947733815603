import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ChangeDetectorRef
} from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LocationService } from 'src/app/services/location/location.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/variables';
import { version as appVersion } from 'src/app/version';
import { InAppBrowser, UrlEvent } from '@capgo/inappbrowser';
import moment from 'moment';
import { TokenService } from 'src/app/services/token/token.service';
import { DefaultsService } from 'src/app/services/defaults/defaults.service';
import { Router } from '@angular/router';

@Component({
  selector: 'sc-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddPaymentComponent implements OnInit {
  @Input() customer: any;
  @Output() success: EventEmitter<any> = new EventEmitter();
  @Input() authPage: boolean = false;
  frameLoaded: boolean = false;
  appConfig: AppConfig = new AppConfig();
  url: SafeResourceUrl;
  currency: string;
  appVersion: string = appVersion;
  diagnostics: any[] = [];
  debug: boolean = false;
  callExit: boolean = true;
  userType = '';
  showGooglePay = false;
  showApplePay = false;
  possiblePaymentMethods = [];
  reservationAmount = 400;

  constructor(
    private modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
    private locationService: LocationService,
    private payment: PaymentService,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private tokenService: TokenService,
    private platform: Platform,
    private defaults: DefaultsService,
    private changeRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.locationService.getCountry().then(
      (country) => {
        if (country && country.CurrencyCode) {
          this.currency = country.CurrencyCode;
          this.init(country.CurrencyCode);
          this.logDiagnostics(country.CurrencyCode, 'getcountrycurrency');
        } else {
          this.logDiagnostics('no-country-found', 'getcountry');
          this.currency = 'NOK';
          this.init(this.currency); //Fallback if something went wrong in getCountry(). This should not happen. It means we couldn't find the country most likely
        }
      },
      (err) => {
        this.logDiagnostics(err, 'getcountryerr');
        this.currency = 'NOK';
        this.init(this.currency); //Fallback if something went wrong in getCountry()
      }
    );
  }
  ionViewWillLeave() {
    if (this.debug) {
      this.exportLog();
    }
  }
  logDiagnostics(logContent, id?) {
    if (this.debug) {
      try {
        this.diagnostics.push({
          date: moment.utc().format('YYYY-MM-DDTHH:mm[Z]'),
          log: logContent,
          id: id
        });
      } catch (err) {
        console.log(err, id);
      }
    }
  }
  exportLog() {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' +
        encodeURIComponent(btoa(JSON.stringify(this.diagnostics)))
    );
    element.setAttribute(
      'download',
      'diagnostics_' + moment.utc().format('YYYY-MM-DDTHH:mm[Z]') + '.txt'
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  init(currency, retry?) {
    this.payment.getToken(currency, this.userType === 'Adhoc').then(
      (btToken) => {
        let btLanguage = this.getBraintreeLanguage();

        //Remove all special characters that might break btoa()
        this.customer.FirstName =
          this.customer?.FirstName?.replace(/[^\x00-\xFF]/g, '').trim() || '';
        this.customer.LastName =
          this.customer?.LastName?.replace(/[^\x00-\xFF]/g, '').trim() || '';
        this.customer.Identificator =
          this.customer?.Identificator?.replace(/[^\x00-\xFF]/g, '').trim() ||
          '';

        let key = {
          btToken: btToken.Token,
          ipAddress: btToken.IP,
          btLanguage: btLanguage,
          Customer: this.customer,
          AuthPage: this.authPage,
          title: this.appConfig.title,
          skipText: this.translate.instant('AUTH.SKIP'),
          registerText: this.translate.instant('AUTH.REGISTER_PAYMENT'),
          textColor: this.appConfig.colors.primary_contrast,
          backgroundColor: this.appConfig.colors.primary,
          Token: this.tokenService.token,
          errorHeader: this.translate.instant('PAYMENT.COULD_NOT_ADD'),
          errorText: this.translate.instant('APP.TRY_AGAIN_OR_SUPPORT'),
          base: this.defaults.baseUrl,
          authAmount: this.reservationAmount,
          Currency: currency,
          isAdhoc: this.userType === 'Adhoc',
          showGooglePay: this.showGooglePay,
          showApplePay: this.showApplePay,
          possiblePaymentMethods: this.possiblePaymentMethods.map(
            ({ PK_PaymentMethodID, PaymentType }) => ({
              PK_PaymentMethodID,
              PaymentType
            })
          )
        };

        let token = encodeURIComponent(btoa(JSON.stringify(key)));

        //this.url = this.sanitizer.bypassSecurityTrustResourceUrl("https://localhost:8101/#/add-payment/"+token+'?v='+this.appVersion);

        if (this.platform.is('capacitor') && this.platform.is('ios')) {
          //Use browser for ios since there can be blocking issues with iframe security.
          //TODO: Need to use a generic solution.
          this.setupBrowser(token);
        } else {
          this.setupIframe(token);
        }
      },
      (err) => {
        this.logDiagnostics(err, 'gettokenerr');
        //To check if the currency is supported. Now we retry with NOK so it will always work unless there is some other error
        /*if(err.errorName==='unsupportedCurrency')*/

        if (!retry) {
          //Retry with NOK once
          this.currency = 'NOK';
          this.init(this.currency, true);
        } else {
          this.showGenericError('COULD_NOT_INITIATE');
        }
      }
    );
  }
  setupIframe(token) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://pay.current.eco/#/add-payment/${token}?v=${this.appVersion}`
    );

    window.onmessage = (e) => {
      if (e.data) {
        if (e.data.btPayload) {
          const payload = e.data.btPayload;

          switch (e.data.btPayload.type) {
            case 'CreditCard':
              payload['paymentMethodID'] = this.possiblePaymentMethods.find(
                (item) =>
                  item.PaymentType === 'BraintreeCardAdhocNorway' ||
                  item.PaymentType === 'BraintreeCardAdhocNorway'
              )?.PK_PaymentMethodID;
              break;
            case 'AndroidPayCard':
              payload['paymentMethodID'] = this.possiblePaymentMethods.find(
                (item) =>
                  item.PaymentType === 'GooglePayAdhocNorway' ||
                  item.PaymentType === 'GooglePayAdhocSweden'
              )?.PK_PaymentMethodID;
              break;
            case 'ApplePayCard':
              payload['paymentMethodID'] = this.possiblePaymentMethods.find(
                (item) =>
                  item.PaymentType === 'ApplePayAdhocNorway' ||
                  item.PaymentType === 'ApplePayAdhocSweden'
              )?.PK_PaymentMethodID;
              break;
          }

          this.createBraintreeCustomer(payload, this.userType);
        } else if (e.data === 'skip') {
          this.skip();
        } else if (e.data === 'cancel') {
          if (this.authPage) {
            this.success.emit(false);
          } else {
            this.modalCtrl.dismiss(false);
          }
        } else if (e.data === 'frame-loaded') {
          this.frameLoaded = true;
          this.changeRef.detectChanges(); //Suddenly had to add this to detect changes
        } else if (e.data.dropinError) {
          //TODO: Could add some nicer text to these errorCodes. For now it is ok
          this.showGenericError(e.data.errorCode);
        }
        if (
          e.data.diagnostics &&
          e.data.diagnostics.log &&
          e.data.diagnostics.id
        ) {
          this.logDiagnostics(e.data.diagnostics.log, e.data.diagnostics.id);
        }
      }
    };
  }
  async setupBrowser(token) {
    //'https://apptestingaccount.z16.web.core.windows.net/#/add-payment/' +

    await InAppBrowser.removeAllListeners();
    await InAppBrowser.addListener('urlChangeEvent', (event: UrlEvent) => {
      if (event.url.indexOf('callback') != -1) {
        this.callExit = false;
        //This happens on success and failure. Check for 'callback/{success:bool}'
        if (!this.authPage) {
          this.modalCtrl.dismiss(true);
        } else {
          this.success.emit(true);
        }
        InAppBrowser.close();
      }
    });
    await InAppBrowser.addListener('closeEvent', () => {
      if (this.callExit) {
        if (!this.authPage) {
          this.modalCtrl.dismiss(true);
        } else {
          this.success.emit(true);
        }
      }
    });

    await InAppBrowser.openWebView({
      url: `https://pay.current.eco/#/add-payment/${token}?v=${this.appVersion}&iab=true`,
      disableGoBackOnNativeApplication: true,
      title: ''
    });
  }
  paymentAdded() {
    if (this.authPage) {
      this.success.emit(true);
    } else {
      this.close();
    }
    this.handleRedirect();
  }

  handleRedirect() {
    const redirectPath = this.payment.redirectPath;

    if (redirectPath) {
      this.router.navigate([redirectPath]);
      this.payment.setRedirectPath(null);
    }
  }

  skip() {
    this.success.emit(false);
  }

  close() {
    this.modalCtrl.dismiss(false);
  }
  showError(header, message) {
    this.alertCtrl
      .create({
        header: header,
        message: message,
        buttons: [
          {
            text: this.translate.instant('APP.CANCEL'),
            role: 'cancel',
            handler: () => {
              if (this.authPage) {
                this.success.emit();
              } else {
                this.modalCtrl.dismiss();
              }
            }
          },
          {
            text: this.translate.instant('APP.TRY_AGAIN'),
            handler: () => {
              this.init(this.currency);
            }
          }
        ]
      })
      .then((alert) => {
        alert.present();
      });
  }
  redirect() {}
  showGenericError(errorCode) {
    let knownErrorCodes = [
      'INITIATION_ERROR',
      'COULD_NOT_REGISTER',
      'COULD_NOT_INITIATE',
      'INVALID_REQUEST'
    ];
    let message = '';
    if (knownErrorCodes.includes(errorCode)) {
      message = this.translate.instant('PAYMENT_REJECT.' + errorCode);
    } else {
      message =
        this.translate.instant('APP.TRY_AGAIN_OR_SUPPORT') +
        '\n' +
        this.translate.instant('PAYMENT.ERROR_CODE', { code: errorCode });
    }

    this.showError(this.translate.instant('PAYMENT.COULD_NOT_ADD'), message);
  }
  private createBraintreeCustomer(payload, userType?: string) {
    this.payment
      .createBraintreeCustomer(payload, this.customer, this.currency, userType)
      .then(
        (res) => {
          this.paymentAdded();
        },
        (err) => {
          console.log(err);
          if (err) {
            if (err.datas && err.datas.message) {
              if (err.datas.message === 'Card Issuer Declined CVV') {
                this.showError(
                  this.translate.instant('PAYMENT.COULD_NOT_ADD'),
                  this.translate.instant('PAYMENT.WRONG_CVV')
                );
              } else {
                this.showGenericError(err.datas.message);
              }
            } else {
              this.showGenericError(err.errorName);
            }
          }
        }
      );
  }
  private getBraintreeLanguage() {
    let btLanguage = 'en_US';
    switch (this.translate.currentLang) {
      case 'nb':
        btLanguage = 'no_NO';
        break;
      case 'sv':
        btLanguage = 'sv_SE';
        break;
      case 'de':
        btLanguage = 'de_DE';
        break;
      case 'en':
        btLanguage = 'en_US';
        break;
      case 'da':
        btLanguage = 'da_DK';
        break;
    }
    return btLanguage;
  }
}
