<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button color="toolbar-button" (click)="cancel()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'AUTH.' + title | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="!isFellTech">
  <span *ngIf="title === 'LOGIN'">
    <div class="reset ion-padding" *ngIf="resetSuccess">
      <h5 class="ion-text-center">
        {{ 'AUTH.FORGOT_PASSWORD_CHECK_EMAIL' | translate }}
      </h5>
    </div>
    <form [formGroup]="loginForm" (keyup.enter)="login()">
      <ion-list>
        <ion-item>
          <ion-input
            formControlName="email"
            (ionFocus)="onFocus('loginForm.email')"
            (ionBlur)="onBlur()"
            [label]="'AUTH.EMAIL' | translate"
            labelPlacement="floating"
            type="email"
            name="email"
            scAutofill
            autocomplete="email"
            email
            required></ion-input>
        </ion-item>
        <ion-item
          class="error"
          no-lines
          *ngIf="
            !loginForm.controls.email.valid &&
            inFocus !== 'loginForm.email' &&
            loginForm.controls.email.dirty
          ">
          <ion-label class="error">
            <p class="error">{{ 'AUTH.ENTER_VALID_EMAIL' | translate }}</p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-input
            formControlName="password"
            [label]="'AUTH.PASSWORD' | translate"
            labelPlacement="floating"
            [type]="showPass ? 'text' : 'password'"
            name="password"
            scAutofill
            autocomplete="current-password"
            required></ion-input>
          <ion-button
            slot="end"
            fill="clear"
            size="default"
            (click)="showPass = !showPass">
            <ion-icon
              slot="icon-only"
              [name]="showPass ? 'eye-off' : 'eye'"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
      <div class="ion-padding">
        <ion-button expand="block" type="button" (click)="login()">
          {{ 'AUTH.LOGIN' | translate }}
        </ion-button>
      </div>
    </form>
    <ion-button expand="block" fill="clear" (click)="show('REGISTER')">
      {{ 'AUTH.CREATE_NEW' | translate }}
    </ion-button>
    <ion-button expand="block" fill="clear" (click)="show('FORGOT_PASSWORD')">
      {{ 'AUTH.FORGOT_PASSWORD' | translate }}?
    </ion-button>
  </span>
  <!-- REGISTER -->
  <span *ngIf="title === 'REGISTER' || title === 'COMPLETE_REGISTRATION'">
    <form
      [formGroup]="registerForm"
      (submit)="register(title)"
      (keyup.enter)="register(title)">
      <ion-list>
        <ion-item>
          <ion-input
            formControlName="firstName"
            type="text"
            name="firstName"
            [label]="'AUTH.FIRST_NAME' | translate"
            labelPlacement="floating"
            scAutofill
            autocomplete="given-name"
            required></ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            formControlName="lastName"
            type="text"
            name="lastName"
            [label]="'AUTH.LAST_NAME' | translate"
            labelPlacement="floating"
            scAutofill
            autocomplete="family-name"
            required></ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            formControlName="email"
            (ionFocus)="onFocus('registerForm.email')"
            (ionBlur)="onBlur()"
            type="email"
            name="email"
            [label]="'AUTH.EMAIL' | translate"
            labelPlacement="floating"
            scAutofill
            autocomplete="email"
            required></ion-input>
        </ion-item>
        <ion-item
          class="error"
          lines="none"
          *ngIf="
            !registerForm.controls.email.valid &&
            inFocus !== 'registerForm.email' &&
            registerForm.controls.email.dirty
          ">
          <ion-label class="error">
            <p class="error">{{ 'AUTH.ENTER_VALID_EMAIL' | translate }}</p>
          </ion-label>
        </ion-item>

        <ion-item>
          <ion-input
            formControlName="password"
            [type]="showPass ? 'text' : 'password'"
            name="password"
            [label]="
              'AUTH.' + (title === 'REGISTER' ? 'PASSWORD' : 'NEW_PASSWORD')
                | translate
            "
            labelPlacement="floating"
            scAutofill
            autocomplete="new-password"
            required></ion-input>
          <ion-button
            slot="end"
            fill="clear"
            size="default"
            (click)="showPass = !showPass">
            <ion-icon
              slot="icon-only"
              [name]="showPass ? 'eye-off' : 'eye'"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
      <div class="ion-padding">
        <ion-button expand="block" type="submit">
          {{ 'AUTH.' + title | translate }}
        </ion-button>
      </div>
    </form>
    <ion-button expand="block" fill="clear" (click)="show('LOGIN')">
      {{ 'AUTH.LOGIN' | translate }}
    </ion-button>
  </span>
  <span *ngIf="title === 'FORGOT_PASSWORD'">
    <p class="ion-padding ion-text-center">
      {{ 'AUTH.FORGOT_PASSWORD_EMAIL' | translate }}
    </p>
    <form
      [formGroup]="forgotPasswordForm"
      (submit)="resetPassword()"
      (keyup.enter)="resetPassword()">
      <ion-list>
        <ion-item>
          <ion-input
            formControlName="email"
            (ionFocus)="onFocus('forgotPasswordForm.email')"
            (ionBlur)="onBlur()"
            type="email"
            name="email"
            [label]="'AUTH.EMAIL' | translate"
            labelPlacement="floating"
            scAutofill
            autocomplete="email"
            email
            required></ion-input>
        </ion-item>
        <ion-item
          class="error"
          lines="none"
          *ngIf="
            !forgotPasswordForm.controls.email.valid &&
            inFocus !== 'forgotPasswordForm.email' &&
            forgotPasswordForm.controls.email.dirty
          ">
          <ion-label class="error">
            <p class="error">{{ 'AUTH.ENTER_VALID_EMAIL' | translate }}</p>
          </ion-label>
        </ion-item>
      </ion-list>

      <div class="ion-padding">
        <ion-button expand="block" type="submit">
          {{ 'AUTH.RESET_PASSWORD' | translate }}
        </ion-button>
      </div>
    </form>
    <ion-button expand="block" fill="clear" (click)="show('LOGIN')">
      {{ 'AUTH.LOGIN' | translate }}
    </ion-button>

    <div class="reset ion-padding" *ngIf="resetSuccess">
      <h5 class="ion-text-center">
        {{ 'AUTH.FORGOT_PASSWORD_CHECK_EMAIL' | translate }}
      </h5>
      <ion-button expand="block" (click)="cancel()">
        {{ 'AUTH.CLOSE' | translate }}
      </ion-button>
    </div>
  </span>
  <span *ngIf="title === 'REGISTER_PAYMENT'">
    <sc-add-payment
      *ngIf="customer"
      (success)="skipPayment()"
      [customer]="customer"
      [authPage]="true"></sc-add-payment>
  </span>
  <span *ngIf="title === 'SUCCESS'" class="ion-padding ion-text-center">
    <svg
      class="checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52">
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path
        class="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
    <h1>{{ 'AUTH.USER_CREATED' | translate }}</h1>
  </span>

  <p
    *ngIf="title === 'LOGIN' || title === 'REGISTER'"
    class="terms"
    (click)="openAgreements()">
    {{ 'AUTH.ACCEPT_TERMS_POLICY' | translate }}
    <ion-icon name="open-outline"></ion-icon>
  </p>
</ion-content>
<ion-content *ngIf="isFellTech">
  <div class="ion-padding ion-margin-top">
    <ion-button expand="block" type="button" (click)="loginWithAbra()">
      {{ 'AUTH.SIGNIN_WITH_ABRA' | translate }}
    </ion-button>
  </div>

  <p class="terms" (click)="openAgreements()">
    {{ 'AUTH.ACCEPT_TERMS_POLICY' | translate }}
    <ion-icon name="open-outline"></ion-icon>
  </p>
</ion-content>
