export class Colors {
  primary: string = '#196ab0';
  primary_dark: string = '#196ab0';
  secondary: string = '#6dab00';
  danger: string = '#f53d3d';
  warning: string = '#FFCC00';
  light: string = '#f8f8f8';
  gray: string = '#808080';
  dark: string = '#222';
  black: string = '#000000';
  white: string = '#ffffff';
  primary_contrast: string = '#ffffff';
  toolbar = '#F8F8F8';
}
export class AppConfig {
  colors: Colors = new Colors();
  title: string = 'SmartCharge';
  appID: string = '';
  appHost: string = 'app.smartcharge.io';
  showTitle: boolean = true;
  defaultLang: string = 'en';
  appToken: string = null;
  readonly isWhitelabel: boolean = this.appToken ? true : false;
  root: string = 'https://scpay.smartcharge.io';
  pushAppID: string = 'ebfdc8c7-f9bd-4dc8-8137-ab9487ba8f9c';
  pushSenderID: string = '419011609167';
  stationIcon: StationIcon = { height: 40, width: 40 };
  toolbarLogoHeight: string = '1.6em';
  availableLangs: string[] = ['en', 'nb', 'de', 'da', 'sv'];
  privacyPolicy: any = {
    nb: 'https://www.current.eco/company/privacy-policy',
    en: 'https://www.current.eco/company/privacy-policy'
  };
  termsAndConditions: any = {
    nb: 'https://www.current.eco/company/terms-and-conditions',
    en: 'https://www.current.eco/company/terms-and-conditions'
  };
  contact: any = {
    external: false,
    email: 'support@smartcharge.io',
    phone: '',
    //web: 'current.eco',
    customerService: {
      nb: 'smartcharge.io',
      en: 'smartcharge.io'
    }
  };
  constructor() {}
}
interface StationIcon {
  height: number;
  width: number;
}
